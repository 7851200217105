<template>
  <div class="scrollable-text-field">
    <v-card flat class="text-field-card">
      <v-card-text class="pa-0">
        <v-textarea
          v-model="localValue"
          :disabled="disabled"
          :loading="loading"
          :placeholder="placeholder"
          :error-messages="errorMessages"
          :rows="rows"
          :max-rows="maxRows"
          :auto-grow="autoGrow"
          :hide-details="hideDetails"
          density="comfortable"
          variant="outlined"
          color="primary"
          class="transcript-textarea fill-height"
          @input="handleInput"
          @change="handleChange"
        >
          <!-- Loading Indicator -->
          <template v-slot:loader>
            <v-progress-linear
              indeterminate
              color="primary"
              height="2"
            ></v-progress-linear>
          </template>
        </v-textarea>
      </v-card-text>

      <!-- Bottom Actions -->
      <v-card-actions v-if="$slots.actions" class="pa-4">
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { ref, watch } from 'vue'

export default {
  name: 'ScrollableTextField',

  props: {
    modelValue: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Enter text...'
    },
    rows: {
      type: [Number, String],
      default: 5
    },
    maxRows: {
      type: [Number, String],
      default: 15
    },
    autoGrow: {
      type: Boolean,
      default: true
    }
  },

  emits: ['update:modelValue', 'input', 'change'],

  setup(props, { emit }) {
    const localValue = ref(props.modelValue)

    watch(() => props.modelValue, (newValue) => {
      if (newValue !== localValue.value) {
        localValue.value = newValue
      }
    })

    const handleInput = (event) => {
      emit('update:modelValue', localValue.value)
      emit('input', event)
    }

    const handleChange = (event) => {
      emit('change', event)
    }

    return {
      localValue,
      handleInput,
      handleChange
    }
  }
}
</script>

<style>
.scrollable-text-field {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.text-field-card {
  background: transparent !important;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.text-field-card .v-card-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
}

.transcript-textarea {
  flex: 1;
  height: 100%;
  display: flex;
}

.transcript-textarea :deep(.v-input__control) {
  height: 100%;
  flex: 1;
}

.transcript-textarea :deep(.v-field) {
  height: 100%;
}

.transcript-textarea :deep(.v-field__field) {
  height: 100%;
}

.transcript-textarea :deep(.v-field__input) {
  height: 100% !important;
  min-height: unset !important;
  font-size: 11px !important;
  line-height: 1.2 !important;
  font-family: monospace !important;
  padding: 8px 12px !important;
  white-space: pre-wrap !important;
  overflow-y: auto !important;
}

.transcript-textarea :deep(.v-field__outline) {
  --v-field-border-width: 1px !important;
}

/* Error State */
.transcript-textarea.v-field--error :deep(.v-field__outline__start),
.transcript-textarea.v-field--error :deep(.v-field__outline__end) {
  border-color: var(--error-color) !important;
}

/* Disabled State */
.transcript-textarea.v-field--disabled {
  opacity: 0.7;
}
</style>
