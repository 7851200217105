<template>
  <div class="app-layout">
    <!-- Header Slot -->
    <div class="app-layout__header">
      <slot name="header"></slot>
    </div>

    <!-- Main Content -->
    <div class="app-layout__content">
      <div class="app-layout__main">
        <slot></slot>
      </div>
    </div>

    <!-- Footer Slot -->
    <div class="app-layout__footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted } from 'vue'

export default {
  name: 'AppLayout',

  setup() {
    const handleResize = () => {
      document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight * 0.01}px`
      )
    }

    onMounted(() => {
      window.addEventListener('resize', handleResize)
      handleResize()
    })

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize)
    })

    return {}
  }
}
</script>

<style>
.app-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: var(--background-color);
}

.app-layout__header {
  flex: 0 0 auto;
}

.app-layout__content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  padding-top: 64px; /* Match app bar height */
}

.app-layout__main {
  flex: 1 0 auto;
  min-width: 0;
  width: 100%;
}

.app-layout__footer {
  flex: 0 0 auto;
}

/* Mobile Responsive Adjustments */
@media (max-width: 960px) {
  .app-layout__content {
    flex-direction: column;
  }
  
  .app-layout__main {
    width: 100%;
  }
}

/* Support for notched devices */
@supports (padding: max(0px)) {
  .app-layout__main {
    padding-left: max(env(safe-area-inset-left), 16px);
    padding-right: max(env(safe-area-inset-right), 16px);
  }
}
</style>