<template>
  <v-container class="home-view pa-4">
    <!-- Loading Overlay -->
    <v-overlay
      v-model="isLoading"
      class="align-center justify-center"
      scrim="white"
      persistent
    >
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <!-- Main Content -->
    <template v-if="pagesWithTablesLogigrams.length > 0">
      <v-card variant="outlined" class="main-card">
        <!-- Card Header -->
        <v-card-title class="header-section">
          <div class="d-flex align-center justify-space-between pa-4">
            <h3 class="text-h5 text-primary font-weight-medium">
              Pages à valider
            </h3>
            <v-chip
              v-if="hasValidatedPages"
              color="primary"
              variant="outlined"
              class="status-chip"
            >
              {{ validationSummary }}
            </v-chip>
          </div>
        </v-card-title>

        <v-divider></v-divider>

        <!-- Main Table -->
        <v-table hover class="main-table">
          <thead>
            <tr>
              <th class="text-left" style="width: 30%">Document</th>
              <th class="text-left" style="width: 20%">Date d'ajout</th>
              <th class="text-center" style="width: 10%">Page</th>
              <th class="text-center" style="width: 20%">Status</th>
              <th class="text-center" style="width: 20%"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="page in pagesWithTablesLogigrams" :key="page.id" class="table-row">
              <!-- Document Name -->
              <td>
                <div class="text-truncate document-name">
                  {{ page.pdf_filename }}
                </div>
              </td>

              <!-- Upload Date -->
              <td class="text-grey-darken-1">{{ formatDate(page.uploaded_at) }}</td>

              <!-- Page Number -->
              <td class="text-center">{{ page.page_number }}</td>

              <!-- Status -->
              <td class="text-center">
                <v-chip
                  :color="page.validated ? 'success' : 'warning'"
                  size="small"
                  variant="outlined"
                  class="status-chip"
                >
                {{ page.validated ? 'Validée' : 'A valider' }}
              </v-chip>
              </td>

              <!-- Actions -->
              <td class="text-center">
                <base-button
                  color="primary"
                  variant="text"
                  size="small"
                  @click="viewPage(page)"
                >
                  View page
                </base-button>
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-card>

      <!-- Action Button -->
      <v-card v-if="pagesWithTablesLogigrams.length > 0" class="action-card mt-6" variant="outlined" flat color="transparent">
        <v-card-actions class="justify-center pa-6">
          <base-button
            color="primary"
            variant="text"
            :loading="isLoading"
            :disabled="!hasUnvalidatedPages || isLoading"
            @click="startValidation"
            size="large"
          >
            {{ getStartValidationText }}
          </base-button>
        </v-card-actions>
      </v-card>
    </template>

    <!-- Empty State -->
    <v-card v-else-if="!isLoading" class="empty-state" variant="outlined">
      <v-card-text class="text-center pa-8">
        <p class="text-h5 text-primary font-weight-medium mb-2">
          Aucun document ne contient de tableau ou de logigramme.
        </p>
        <!-- <p class="text-body-1 text-grey-darken-1">
          Commencez par télécharger un PDF contenant des tableaux ou des logigrammes.
        </p> -->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import axios from '@/utils/axios-config'

export default {
  name: 'HomeView',

  setup() {
    const router = useRouter()
    
    // State
    const pagesWithTablesLogigrams = ref([])
    const isLoading = ref(false)

    // Computed
    const hasUnvalidatedPages = computed(() => 
      pagesWithTablesLogigrams.value.some(page => !page.validated)
    )

    const hasValidatedPages = computed(() => 
      pagesWithTablesLogigrams.value.some(page => page.validated)
    )

    const validationSummary = computed(() => {
      const total = pagesWithTablesLogigrams.value.length
      const validated = pagesWithTablesLogigrams.value.filter(page => page.validated).length
      return `${validated} / ${total} validées `
    })

    const getStartValidationText = computed(() => {
      if (isLoading.value) return 'Chargement...'
      if (!hasUnvalidatedPages.value) return 'Démarrer'
      return 'Démarrer'
    })

    // Methods
    const refreshData = async (force = false) => {
      if (isLoading.value && !force) return
      
      try {
        isLoading.value = true
        const response = await axios.get('/api/pages/with-tables-logigrams')
        
        // Sort pages: unvalidated first, then by filename and page number
        pagesWithTablesLogigrams.value = response.data.sort((a, b) => {
          if (a.validated !== b.validated) {
            return a.validated ? 1 : -1
          }
          const filenameDiff = a.pdf_filename.localeCompare(b.pdf_filename)
          return filenameDiff !== 0 ? filenameDiff : a.page_number - b.page_number
        })
      } catch (err) {
        console.error('Error fetching pages:', err)
      } finally {
        isLoading.value = false
      }
    }

    const viewPage = async (page) => {
      try {
        const response = await axios.get(`/api/pages/${page.id}`)
        const updatedPage = {
          ...response.data,
          image_url: `${axios.defaults.baseURL}${page.image_url}`
        }

        router.push({
          name: 'ImageView',
          params: {
            selectedPdfs: page.pdf_id.toString(),
            filteredImages: JSON.stringify([updatedPage])
          }
        })
      } catch (err) {
        console.error('Error viewing page:', err)
      }
    }

    const startValidation = async () => {
      if (!hasUnvalidatedPages.value) return
      
      try {
        isLoading.value = true
        
        // Get unique PDF IDs from unvalidated pages
        const pdfIds = [...new Set(
          pagesWithTablesLogigrams.value
            .filter(page => !page.validated)
            .map(page => page.pdf_id)
        )]

        // Fetch unvalidated pages for these PDFs
        const response = await axios.post('/api/pages/unvalidated-with-tables-logigrams', pdfIds)
        
        // Process the response
        const unvalidatedPages = response.data.map(page => ({
          ...page,
          image_url: `${axios.defaults.baseURL}${page.image_url}`
        }))

        // If no pages to validate, refresh the data
        if (unvalidatedPages.length === 0) {
          await refreshData(true)
          return
        }

        // Navigate to ImageView with the unvalidated pages
        router.push({
          name: 'ImageView',
          params: {
            selectedPdfs: pdfIds.join(','),
            filteredImages: JSON.stringify(unvalidatedPages)
          }
        })
      } catch (err) {
        console.error('Error starting validation:', err)
      } finally {
        isLoading.value = false
      }
    }

    const formatDate = (date) => {
      if (!date) return '-'
      try {
        return new Date(date).toLocaleString('fr-FR', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })
      } catch (error) {
        console.error('Error formatting date:', error)
        return date
      }
    }

    // Lifecycle
    onMounted(() => {
      refreshData()
    })

    return {
      // State
      pagesWithTablesLogigrams,
      isLoading,

      // Computed
      hasUnvalidatedPages,
      hasValidatedPages,
      validationSummary,
      getStartValidationText,

      // Methods
      refreshData,
      formatDate,
      viewPage,
      startValidation
    }
  }
}
</script>

<style>
.home-view {
  min-height: 100%;
  background-color: var(--background-color);
}

.main-card {
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--grey-lighten-2);
  overflow: hidden;
}

.header-section {
  background-color: white;
}

.main-table {
  width: 100%;
}

.main-table th {
  font-weight: 500;
  color: var(--primary-color);
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  background-color: var(--grey-lighten-2);
  padding: 12px 16px;
}

.table-row {
  transition: background-color 0.2s ease;
}

.table-row:hover {
  background-color: var(--grey-lighten-2);
}

.table-row td {
  padding: 12px 16px;
  height: 64px;
  vertical-align: middle;
}

.document-name {
  color: var(--primary-color);
  font-weight: 500;
  max-width: 300px;
  cursor: pointer;
}

.status-chip {
  font-weight: 500;
  text-transform: none;
}

.action-card {
  background-color: white;
  border-radius: var(--border-radius);
}

.empty-state {
  border-radius: var(--border-radius);
  background-color: white;
  border: 1px solid var(--grey-lighten-2);
}

/* Loading Overlay */
.v-overlay__scrim {
  backdrop-filter: blur(2px);
}

.v-overlay__content {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  padding: 24px;
}
</style>