<template>
  <v-app>
    <app-layout>
      <template #header>
        <v-app-bar 
          app
          color="white"
          elevation="1"
          height="64"
          class="app-header"
        >
          <div class="d-flex align-center px-4 app-header-content">
            <div class="d-flex align-center">
              <img
                :src="logo"
                alt="Recital Logo"
                class="logo"
                height="40"
              />
              <v-toolbar-title class="text-h5 text-primary font-weight-medium ml-3">
                Transcript
              </v-toolbar-title>
            </div>

            <v-spacer></v-spacer>

            <!-- Navigation -->
            <router-link 
              to="/" 
              v-if="$route.name !== 'HomeView'"
              class="text-decoration-none"
            >
              <v-btn
                color="primary"
                class="text-none font-weight-medium"
                variant="text"
                rounded
              >
                Back to Home
              </v-btn>
            </router-link>
          </div>
        </v-app-bar>
      </template>

      <!-- Main Content -->
      <v-main class="bg-grey-lighten-4">
        <v-container 
          fluid 
          :class="{
            'pa-0': $route.name === 'HomeView',
            'pa-2': $route.name !== 'HomeView'
          }"
        >
          <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
        </v-container>

        <!-- Loading Overlay -->
        <v-overlay
          :model-value="isLoading"
          class="align-center justify-center"
          scrim="#ffffff"
          persistent
        >
          <v-progress-circular
            color="primary"
            indeterminate
            size="64"
          ></v-progress-circular>
        </v-overlay>
      </v-main>

      <!-- Global Error Snackbar -->
      <v-snackbar
        v-model="showError"
        color="error"
        location="bottom right"
        :timeout="5000"
        rounded="pill"
      >
        {{ errorMessage }}
        
        <template v-slot:actions>
          <v-btn
            color="white"
            variant="text"
            @click="showError = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </app-layout>
  </v-app>
</template>

<script>
import { ref } from 'vue'
import AppLayout from './components/layout/AppLayout.vue'
import logo from '@/assets/images/recital_logo-removebg-preview.png'

export default {
  name: 'App',

  components: {
    AppLayout
  },

  setup() {
    const isLoading = ref(false)
    const showError = ref(false)
    const errorMessage = ref('')

    const showErrorMessage = (message) => {
      errorMessage.value = message
      showError.value = true
    }

    return {
      isLoading,
      showError,
      errorMessage,
      showErrorMessage,
      logo
    }
  }
}
</script>

<style scoped>
.app-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  border-bottom: 1px solid var(--grey-lighten-2);
}

.app-header-content {
  width: 100%;
  height: 100%;
}

.logo {
  width: auto;
  height: 40px;
  object-fit: contain;
}

/* Fix main content positioning */
:deep(.v-main) {
  padding-top: 2px !important;
}

/* Ensure proper spacing in the layout */
:deep(.v-container) {
  max-width: none;
}

/* Transition animations */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>