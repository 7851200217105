import { createApp } from 'vue'
import { createPinia } from 'pinia'

// Base Vue
import App from './App.vue'
import router from './router'

// Store
import { store } from './plugins/store'

// Vuetify
import 'vuetify/styles'
import vuetify from './plugins/vuetify'

// Icons and Fonts - Import Order Matters
import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

// Base Components
import BaseButton from '@/components/base/BaseButton.vue'
import ScrollableTextField from '@/components/base/ScrollableTextField.vue'
import AppLayout from '@/components/layout/AppLayout.vue'

// Axios
import axiosInstance from './utils/axios-config'

const app = createApp(App)
const pinia = createPinia()

// Register components and plugins
app.component('BaseButton', BaseButton)
app.component('ScrollableTextField', ScrollableTextField)
app.component('AppLayout', AppLayout)

app.use(pinia)
app.use(router)
app.use(vuetify)
app.use(store)

app.config.globalProperties.$axios = axiosInstance

app.mount('#app')

export default app