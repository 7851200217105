import { createStore } from 'vuex';

export const store = createStore({
  state: {
    config: {},
    currentPage: null,
    isLoading: false,
    error: null,
    websocketStatus: {
      isConnected: false,
      lastUpdate: null
    }
  },

  getters: {
    config: state => state.config,
    currentPage: state => state.currentPage,
    isLoading: state => state.isLoading,
    error: state => state.error,
    websocketStatus: state => state.websocketStatus,
  },

  mutations: {
    setConfig(state, payload) {
      state.config = payload;
      state.config.loaded = true;
    },
    
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    
    setLoading(state, status) {
      state.isLoading = status;
    },
    
    setError(state, error) {
      state.error = error;
    },
    
    setWebsocketStatus(state, { isConnected, lastUpdate = null }) {
      state.websocketStatus = {
        isConnected,
        lastUpdate: lastUpdate || state.websocketStatus.lastUpdate
      };
    },
    
    updateWebsocketLastUpdate(state) {
      state.websocketStatus.lastUpdate = new Date();
    }
  },

  actions: {
    async initializeConfig({ commit }) {
      try {
        const response = await fetch('/api/config');
        const config = await response.json();
        commit('setConfig', config);
      } catch (error) {
        console.error('Error loading config:', error);
        commit('setError', 'Failed to load configuration');
      }
    },
    
    async updatePage({ commit }, pageData) {
      commit('setCurrentPage', pageData);
      commit('updateWebsocketLastUpdate');
    },
    
    setWebsocketConnected({ commit }, status) {
      commit('setWebsocketStatus', { isConnected: status });
    }
  }
});

export default store;