<template>
  <v-btn
    :color="color"
    :variant="variant"
    :size="size"
    :density="density"
    :disabled="disabled"
    :loading="loading"
    :rounded="rounded"
    :block="block"
    :class="[
      'base-btn',
      `text-${textTransform}`,
      { 'icon-end': iconPosition === 'end' }
    ]"
    v-bind="$attrs"
    @click="handleClick"
  >
    <v-icon
      v-if="icon"
      :icon="icon"
      :start="iconPosition === 'start'"
      :end="iconPosition === 'end'"
      :size="iconSize"
      :class="{ 'icon-animate': animateIcon }"
    />
    <span 
      v-if="$slots.default"
      :class="{ 
        'ml-2': icon && iconPosition === 'start', 
        'mr-2': icon && iconPosition === 'end' 
      }"
    >
      <slot></slot>
    </span>
  </v-btn>
</template>

<script>
export default {
  name: 'BaseButton',
  
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    variant: {
      type: String,
      default: 'text'
    },
    size: {
      type: String,
      default: undefined
    },
    density: {
      type: String,
      default: 'comfortable'
    },
    icon: {
      type: String,
      default: ''
    },
    iconPosition: {
      type: String,
      default: 'start',
      validator: (value) => ['start', 'end'].includes(value)
    },
    iconSize: {
      type: [String, Number],
      default: 'small'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: true
    },
    block: {
      type: Boolean,
      default: false
    },
    textTransform: {
      type: String,
      default: 'none',
      validator: (value) => ['none', 'uppercase', 'capitalize'].includes(value)
    },
    animateIcon: {
      type: Boolean,
      default: true
    }
  },

  emits: ['click'],

  setup(props, { emit }) {
    const handleClick = (event) => {
      if (!props.disabled && !props.loading) {
        emit('click', event)
      }
    }

    return {
      handleClick
    }
  }
}
</script>

<style scoped>
.base-btn {
  letter-spacing: normal !important;
  font-weight: 500 !important;
  transition: all 0.2s ease;
}

.base-btn:not(.v-btn--disabled) {
  opacity: 0.95;
}

.base-btn:hover:not(.v-btn--disabled) {
  opacity: 1;
  transform: translateY(-1px);
}

.base-btn:active:not(.v-btn--disabled) {
  transform: translateY(0);
}

.base-btn .icon-animate {
  transition: transform 0.2s ease;
}

.base-btn:hover .icon-animate {
  transform: translateX(2px);
}

.base-btn.icon-end:hover .icon-animate {
  transform: translateX(-2px);
}

/* Variant Specific Styles */
.base-btn.v-btn--variant-outlined {
  border-width: 1.5px;
}

.base-btn.v-btn--variant-text:hover {
  background-color: rgba(var(--v-theme-primary), 0.04);
}

/* Loading State */
.base-btn.v-btn--loading {
  opacity: 0.8;
}

/* Size Adjustments */
.base-btn.v-btn--density-comfortable {
  height: 40px;
  padding: 0 16px;
}

.base-btn.v-btn--density-compact {
  height: 32px;
  padding: 0 12px;
}
</style>