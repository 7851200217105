import axios from 'axios';

// Configuration constants
const apiUrl = process.env.VUE_APP_API_URL;
const apiKey = process.env.VUE_APP_API_KEY || 'toto';
const wsUrl = process.env.VUE_APP_WS_URL;

// WebSocket helper functions
export const createWebSocket = (endpoint, {
  onOpen = () => {},
  onMessage = () => {},
  onClose = () => {},
  onError = () => {}
} = {}) => {
  const url = `${wsUrl}${endpoint}`;
  const ws = new WebSocket(url);

  ws.onopen = onOpen;
  ws.onmessage = onMessage;
  ws.onclose = onClose;
  ws.onerror = onError;

  return ws;
};

// Retry mechanism with exponential backoff
export const retryWithBackoff = async (
  fn,
  retries = 3,
  backoff = 300,
  maxBackoff = 5000
) => {
  try {
    return await fn();
  } catch (error) {
    if (retries === 0) {
      throw error;
    }
    await new Promise(resolve => 
      setTimeout(resolve, Math.min(backoff, maxBackoff))
    );
    return retryWithBackoff(
      fn,
      retries - 1,
      backoff * 2,
      maxBackoff
    );
  }
};

// Create axios instance
const axiosInstance = axios.create({
  baseURL: apiUrl,
  headers: {
    'Accept': 'application/json',
    'X-API-TOKEN': apiKey
  },
  withCredentials: false,
  timeout: 30000
});

// Request interceptor
axiosInstance.interceptors.request.use(
  config => {
    // Always include API key
    config.headers['X-API-TOKEN'] = apiKey;

    // Special handling for FormData
    if (config.data instanceof FormData) {
      delete config.headers['Content-Type'];
    } else {
      config.headers['Content-Type'] = 'application/json';
    }

    // Log request in development
    if (process.env.NODE_ENV === 'development') {
      console.log('Request:', {
        url: config.url,
        method: config.method,
        headers: config.headers,
        data: config.data instanceof FormData ? '[FormData]' : config.data
      });
    }

    return config;
  },
  error => {
    console.error('Request configuration error:', error);
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  response => {
    // Log successful response in development
    if (process.env.NODE_ENV === 'development') {
      console.log('Response:', {
        status: response.status,
        data: response.data,
        headers: response.headers
      });
    }
    return response;
  },
  error => {
    if (error.response) {
      console.error('Response error:', {
        status: error.response.status,
        data: error.response.data,
        headers: error.response.headers
      });

      // Handle specific status codes
      switch (error.response.status) {
        case 400:
          console.error('Bad request:', error.response.data.detail);
          break;
        case 401:
          console.error('Authentication error - please log in again');
          break;
        case 403:
          console.error('API Key authentication failed');
          break;
        case 404:
          console.error('Resource not found');
          break;
        case 422:
          console.error('Validation error:', error.response.data.detail);
          break;
        case 500:
          console.error('Server error:', error.response.data.detail);
          break;
        default:
          console.error(`Error ${error.response.status}:`, error.response.data);
      }
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Request setup error:', error.message);
    }

    return Promise.reject(error);
  }
);

// Utility methods
axiosInstance.uploadFile = async function(url, file, onUploadProgress) {
  const formData = new FormData();
  formData.append('file', file);
  
  return this.post(url, formData, {
    headers: {
      'X-API-TOKEN': apiKey
    },
    onUploadProgress
  });
};

// Health check method
axiosInstance.healthCheck = async function() {
  try {
    const response = await this.get('/health');
    return response.data;
  } catch (error) {
    console.error('Health check failed:', error);
    throw error;
  }
};

export default axiosInstance;