import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/Home.vue';
import ImageView from '../components/ImageView.vue';

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/images/:selectedPdfs/:filteredImages',
    name: 'ImageView',
    component: ImageView,
    props: true,
    beforeEnter: (to, from, next) => {
      // Validate route parameters
      try {
        const { selectedPdfs, filteredImages } = to.params;
        
        // Check if selectedPdfs is a valid comma-separated list of numbers
        if (!selectedPdfs || !/^\d+(,\d+)*$/.test(selectedPdfs)) {
          throw new Error('Invalid PDF selection');
        }

        // Check if filteredImages is valid JSON
        const parsedImages = JSON.parse(filteredImages);
        if (!Array.isArray(parsedImages) || parsedImages.length === 0) {
          throw new Error('Invalid filtered images data');
        }

        next();
      } catch (error) {
        console.error('Route validation error:', error);
        next('/');
      }
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Global navigation guard
router.beforeEach((to, from, next) => {
  // Reset scroll position
  if (to.path !== from.path) {
    window.scrollTo(0, 0);
  }
  next();
});

export default router;