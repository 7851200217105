<template>
  <div class="image-view">
    <v-container fluid class="pa-2">
      <!-- Main Content -->
      <v-row v-if="currentPage" class="fill-height" dense>
        <!-- Image Section -->
        <v-col cols="12" md="6" class="pa-1">
          <v-card class="content-card">
            <v-card-title class="py-2 px-4">
              <div class="d-flex align-center justify-space-between w-100">
                <span class="text-caption">{{ currentPage.pdf_filename }} - Page {{ currentPage.page_number }}</span>
              </div>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text class="pa-2">
              <div class="image-wrapper" @click="openFullscreen">
                <v-img
                  :src="currentPage.image_url"
                  :alt="'Page ' + currentPage.page_number"
                  @load="handleImageLoad"
                  @error="handleImageError"
                  :class="{ 'hidden': imageLoading }"
                  cover
                  class="cursor-pointer"
                >
                  <template v-slot:placeholder>
                    <div class="d-flex align-center justify-center fill-height">
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                  </template>
                </v-img>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Transcript Section -->
        <v-col cols="12" md="6" class="pa-1">
          <v-card class="content-card d-flex flex-column flex-grow-1">
            <v-card-title class="py-2 px-4">
              <div class="d-flex align-center justify-space-between w-100">
                <span class="text-caption">Transcription</span>
              </div>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text class="transcript-container flex-grow-1 pa-0">
              <textarea
                v-model="currentTranscript"
                :disabled="isLoading"
                class="custom-native-textarea"
                :class="{ 'error': transcriptErrors.length > 0 }"
              ></textarea>
            </v-card-text>

            <!-- Action Buttons -->
            <v-card-actions class="pa-2">
              <v-row justify="space-between" no-gutters>
                <v-col cols="auto">
                  <base-button
                    :disabled="!canRegenerate || isLoading"
                    @click="generateTranscript"
                    color="primary"
                    variant="text"
                    :loading="isProcessing"
                  >
                    Régénérer
                  </base-button>
                </v-col>

                <v-col cols="auto">
                  <base-button
                    @click="validateTranscript"
                    color="primary"
                    variant="text"
                    :loading="isProcessing"
                  >
                    {{ currentPage.validated ? 'Valider' : 'Valider' }}
                  </base-button>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Navigation at bottom -->
    <div class="navigation-container">
      <div class="navigation d-flex align-center">
        <base-button
          :disabled="currentPageIndex === 0 || isLoading"
          @click="previousPage"
          color="primary"
          variant="text"
          class="mx-2"
        >
          &lt;
        </base-button>

        <div class="text-caption text-grey-darken-1 mx-4">
          Page {{ currentPageIndex + 1 }} sur {{ pages.length }}
        </div>

        <base-button
          :disabled="currentPageIndex === pages.length - 1 || isLoading"
          @click="nextPage"
          color="primary"
          variant="text"
          class="mx-2"
        >
          &gt;
        </base-button>
      </div>
    </div>

    <!-- Fullscreen Image Dialog -->
    <v-dialog
      v-model="showFullscreen"
      fullscreen
      transition="dialog-scale"
    >
      <v-card class="fullscreen-dialog">
        <v-toolbar 
          color="primary" 
          class="dialog-toolbar"
          density="compact"
        >
          <v-btn
            variant="text"
            @click="showFullscreen = false"
            size="small"
          >
          X
          </v-btn>
          <v-toolbar-title class="text-subtitle-2">
            {{ currentPage?.pdf_filename }} - Page {{ currentPage?.page_number }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="fullscreen-image-container">
          <v-img
            :src="currentPage?.image_url"
            :alt="'Page ' + currentPage?.page_number"
            contain
            class="fullscreen-image"
            max-height="92vh"
            width="95%"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Loading Overlay -->
    <v-overlay
      v-model="isLoading"
      class="align-center justify-center"
      scrim="white"
      persistent
    >
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      />
    </v-overlay>

    <!-- Error Snackbar -->
    <v-snackbar
      v-model="showError"
      color="error"
      timeout="5000"
      location="bottom right"
    >
      {{ error }}
      <template v-slot:actions>
        <v-btn
          color="white"
          variant="text"
          @click="showError = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from '@/utils/axios-config'
import debounce from 'lodash/debounce'

export default {
  name: 'ImageView',
  
  setup() {
    const route = useRoute()
    const router = useRouter()
    
    // State
    const pages = ref([])
    const currentPageIndex = ref(0)
    const currentTranscript = ref('')
    const originalTranscript = ref('')
    const isLoading = ref(false)
    const isProcessing = ref(false)
    const imageLoading = ref(true)
    const error = ref(null)
    const showError = ref(false)
    const transcriptArea = ref(null)
    const transcriptErrors = ref([])
    const showFullscreen = ref(false)

    // Computed Properties
    const currentPage = computed(() => pages.value[currentPageIndex.value] || null)
    
    const transcriptChanged = computed(() => 
      currentTranscript.value?.trim() !== originalTranscript.value?.trim()
    )

    const canRegenerate = computed(() => {
      if (!currentPage.value) return false
      return currentPage.value.contains_table || currentPage.value.contains_logigram
    })

    const canValidate = computed(() => 
      !isLoading.value && 
      !isProcessing.value && 
      currentTranscript.value?.trim() &&
      !transcriptChanged.value
    )

    const transcriptPlaceholder = computed(() => {
      if (!currentPage.value) return 'Loading...'
      if (!canRegenerate.value) return 'This page does not contain a table or logigram'
      return 'Transcript will appear here...'
    })

    // Methods
    const confirm = (message) => {
      return new Promise(resolve => {
        resolve(window.confirm(message))
      })
    }

    const showErrorMessage = (message) => {
      error.value = message
      showError.value = true
    }

    const initializePages = async () => {
      try {
        const { selectedPdfs, filteredImages: filteredImagesParam } = route.params
        
        if (!selectedPdfs || !filteredImagesParam) {
          throw new Error('Missing required route parameters')
        }

        const filteredImages = JSON.parse(filteredImagesParam)
        pages.value = await Promise.all(
          filteredImages.map(async (image) => {
            try {
              const response = await axios.get(`/api/pages/${image.id}`)
              return {
                ...response.data,
                image_url: image.image_url
              }
            } catch (error) {
              console.error(`Error fetching page ${image.id}:`, error)
              return image
            }
          })
        )

        if (pages.value.length > 0) {
          updateCurrentTranscript()
        }
      } catch (error) {
        console.error('Error initializing pages:', error)
        showErrorMessage('Error loading pages')
        router.push('/')
      }
    }

    const updateCurrentTranscript = () => {
      if (currentPage.value) {
        currentTranscript.value = currentPage.value.transcript || ''
        originalTranscript.value = currentPage.value.transcript || ''
        transcriptErrors.value = []
      }
    }

    const handleTranscriptChange = debounce(() => {
      if (transcriptArea.value) {
        transcriptArea.value.style.height = 'auto'
        transcriptArea.value.style.height = `${transcriptArea.value.scrollHeight}px`
      }
    }, 100)

    const refreshPageData = async (pageId) => {
      try {
        const response = await axios.get(`/api/pages/${pageId}`)
        const index = pages.value.findIndex(p => p.id === pageId)
        if (index !== -1) {
          const currentImageUrl = pages.value[index].image_url
          pages.value[index] = {
            ...response.data,
            image_url: currentImageUrl
          }
          if (currentPageIndex.value === index) {
            updateCurrentTranscript()
          }
        }
      } catch (error) {
        console.error('Error refreshing page data:', error)
      }
    }

    const handlePageNavigation = async (direction) => {
      if (transcriptChanged.value) {
        const confirmed = await confirm('You have unsaved changes. Do you want to continue?')
        if (!confirmed) return
      }
      
      currentPageIndex.value = direction === 'next' 
        ? currentPageIndex.value + 1 
        : currentPageIndex.value - 1
      
      updateCurrentTranscript()
    }

    const previousPage = () => handlePageNavigation('prev')
    const nextPage = () => handlePageNavigation('next')

    const generateTranscript = async () => {
      if (!canRegenerate.value || isProcessing.value) return
      
      if (transcriptChanged.value) {
        const confirmed = await confirm('You have unsaved changes. Continue anyway?')
        if (!confirmed) return
      }

      isProcessing.value = true
      transcriptErrors.value = []

      try {
        const response = await axios.post(
          `/api/pages/${currentPage.value.id}/generate-transcript`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          }
        )
        
        if (response.data?.data?.transcript) {
          currentTranscript.value = response.data.data.transcript
          originalTranscript.value = response.data.data.transcript
          await refreshPageData(currentPage.value.id)
        } else {
          throw new Error('Invalid response format')
        }
      } catch (err) {
        console.error('Error generating transcript:', err)
        const errorMessage = err.response?.data?.detail || 'Error generating transcript'
        showErrorMessage(errorMessage)
        transcriptErrors.value = [errorMessage]
      } finally {
        isProcessing.value = false
      }
    }


    const saveTranscript = async () => {
      if (!transcriptChanged.value || isProcessing.value) return

      isProcessing.value = true
      transcriptErrors.value = []

      try {
        await axios.put(
          `/api/pages/${currentPage.value.id}/transcript`,
          { transcript: currentTranscript.value.trim() }
        )
        originalTranscript.value = currentTranscript.value.trim()
      } catch (err) {
        const errorMessage = err.response?.data?.detail || 'Error saving transcript'
        showErrorMessage(errorMessage)
        transcriptErrors.value = [errorMessage]
      } finally {
        isProcessing.value = false
      }
    }

    const validateTranscript = async () => {
      if (isProcessing.value) return
      
      isProcessing.value = true
      transcriptErrors.value = []

      try {
        // First save the transcript if there are changes
        if (transcriptChanged.value) {
          await axios.put(
            `/api/pages/${currentPage.value.id}/transcript`,
            { transcript: currentTranscript.value.trim() }
          )
          originalTranscript.value = currentTranscript.value.trim()
        }

        // Then validate
        await axios.put(`/api/pages/${currentPage.value.id}/validate`)
        await refreshPageData(currentPage.value.id)

        // Move to next unvalidated page if available
        const nextUnvalidated = pages.value.findIndex((page, index) => 
          index > currentPageIndex.value && !page.validated
        )
        
        if (nextUnvalidated !== -1) {
          currentPageIndex.value = nextUnvalidated
          updateCurrentTranscript()
        }
      } catch (err) {
        const errorMessage = err.response?.data?.detail || 'Error validating transcript'
        showErrorMessage(errorMessage)
        transcriptErrors.value = [errorMessage]
      } finally {
        isProcessing.value = false
      }
    }

    const validateAndSaveTranscript = async () => {
      if (!canValidate.value || isProcessing.value) return

      isProcessing.value = true
      transcriptErrors.value = []

      try {
        // Always save current transcript before validating
        if (transcriptChanged.value) {
          await axios.put(
            `/api/pages/${currentPage.value.id}/transcript`,
            { transcript: currentTranscript.value.trim() }
          )
          originalTranscript.value = currentTranscript.value.trim()
        }

        // Then validate
        await axios.put(`/api/pages/${currentPage.value.id}/validate`)
        await refreshPageData(currentPage.value.id)
        
        // Find and move to next unvalidated page
        const nextUnvalidated = pages.value.findIndex((page, index) => 
          index > currentPageIndex.value && !page.validated
        )
        
        if (nextUnvalidated !== -1) {
          currentPageIndex.value = nextUnvalidated
          updateCurrentTranscript()
        }
      } catch (err) {
        const errorMessage = err.response?.data?.detail || 'Error saving/validating transcript'
        showErrorMessage(errorMessage)
        transcriptErrors.value = [errorMessage]
      } finally {
        isProcessing.value = false
      }
    }

    const handleImageLoad = () => {
      imageLoading.value = false
    }

    const openFullscreen = () => {
      showFullscreen.value = true
    }
    const handleImageError = () => {
      imageLoading.value = false
      showErrorMessage("Error loading image")
    }

    // Lifecycle Hooks
    onMounted(async () => {
      isLoading.value = true
      try {
        await initializePages()
      } finally {
        isLoading.value = false
      }
    })

    // Watchers
    watch(error, (newError) => {
      showError.value = !!newError
    })

    watch(currentPageIndex, () => {
      imageLoading.value = true
    })

    return {
      // State
      pages,
      currentPageIndex,
      currentPage,
      currentTranscript,
      isLoading,
      isProcessing,
      imageLoading,
      error,
      transcriptArea,
      transcriptErrors,
      transcriptChanged,
      showError,
      showFullscreen,
      openFullscreen,
      saveTranscript,
      validateTranscript,
      // Computed
      canRegenerate,
      canValidate,
      transcriptPlaceholder,

      // Methods
      previousPage,
      nextPage,
      generateTranscript,
      validateAndSaveTranscript,
      handleTranscriptChange,
      handleImageLoad,
      handleImageError,
      showErrorMessage
    }
  }
}
</script>

<style>
.image-view {
  min-height: 100vh;
  background-color: var(--v-background-base);
  position: relative;
  padding-bottom: 4px;
  padding-top: 2px;
}

.content-card {
  height: calc(85vh - 64px);
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--grey-lighten-2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.transcript-container {
  flex: 1;
  display: flex;
  position: relative;
  overflow: hidden;
  min-height: 0;
  padding: 0 !important;
}

.image-wrapper {
  height: calc(100% - 20px);
  min-height: 300px;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  background-color: var(--v-grey-lighten-4);
}

.custom-native-textarea {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 12px;
  margin: 0;
  font-size: 11px;
  line-height: 1.2;
  font-family: monospace;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  resize: none;
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
}

.custom-native-textarea:focus {
  outline: none;
  border-color: var(--v-primary-base, #1976d2);
}

.custom-native-textarea:disabled {
  background-color: rgba(0, 0, 0, 0.03);
  color: rgba(0, 0, 0, 0.38);
}

.custom-native-textarea.error {
  border-color: var(--v-error-base, #ff5252);
}

.v-card-actions {
  min-height: 40px !important; 
  padding: 4px 12px !important;
}

.v-card__text {
  flex: 1 1 auto;
  position: relative;
  min-height: 0;
}

.text-content {
  font-size: 0.75rem;
  line-height: 1.2;
}

.base-button {
  margin: 0 4px;
}

.hidden {
  visibility: hidden;
}

.page-unique .v-card-title {
  padding: 8px 12px !important;
  min-height: 40px !important;
}

:deep(.v-btn) {
  text-transform: none;
  letter-spacing: normal;
}

.navigation-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background: white;
  border-top: 1px solid var(--grey-lighten-2);
  z-index: 100;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

@media (max-width: 960px) {
  .content-card {
    height: 50vh;
    margin-bottom: 16px;
  }
}

.image-wrapper img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.fullscreen-dialog {
  background-color: rgba(0, 0, 0, 0.10) !important;
}

.dialog-toolbar {
  height: 40px !important;
  background-color: rgba(var(--v-theme-primary), 0.20) !important;
}

.fullscreen-image-container {
  height: calc(100vh - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  background-color: rgba(0, 0, 0, 0.10);
}

.fullscreen-image {
  max-width: 98% !important;
  max-height: 90vh !important;
  object-fit: contain !important;
}

.dialog-scale-enter-active,
.dialog-scale-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.dialog-scale-enter-from {
  opacity: 0;
  transform: scale(0.9);
}

.dialog-scale-leave-to {
  opacity: 0;
  transform: scale(1.1);
}

.dialog-scale-enter-to,
.dialog-scale-leave-from {
  opacity: 1;
  transform: scale(1);
}
</style>