import { createVuetify } from 'vuetify'
import { aliases, fa } from 'vuetify/iconsets/fa-svg'
import { mdi } from 'vuetify/iconsets/mdi'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Import FontAwesome core and icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { 
  faEye, 
  faTrash, 
  faChevronLeft, 
  faChevronRight,
  faHourglassEnd,
  faRefresh,
  faSave,
  faCheck,
  faSearch,
  faExpand,
  faTimes
} from '@fortawesome/free-solid-svg-icons'

// Add icons to library
library.add(
  faEye, 
  faTrash, 
  faChevronLeft, 
  faChevronRight,
  faHourglassEnd,
  faRefresh,
  faSave,
  faCheck,
  faSearch,
  faExpand,
  faTimes
)

export default createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'fa',
    aliases: {
      ...aliases,
      eye: 'fas fa-eye',
      trash: 'fas fa-trash',
      chevronLeft: 'fas fa-chevron-left',
      chevronRight: 'fas fa-chevron-right',
      hourglass: 'fas fa-hourglass-end',
      refresh: 'fas fa-refresh',
      save: 'fas fa-save',
      check: 'fas fa-check',
      search: 'fas fa-search',
      expand: 'fas fa-expand',
      close: 'fas fa-times'
    },
    sets: {
      fa,
      mdi
    }
  },
  theme: {
    defaultTheme: 'lightTheme',
    themes: {
      lightTheme: {
        dark: false,
        colors: {
          primary: '#502BFF',
          'primary-lighten1': '#9985FF',
          'primary-lighten2': '#CCC2FF',
          'primary-darken1': '#0000CA',
          'primary-darken2': '#14007A',
          'primary-darken3': '#07002D',
          secondary: '#0F64FF',
          'secondary-lighten1': '#6D91FF',
          'secondary-darken1': '#0000CA',
          'secondary-darken2': '#14007A',
          warning: '#F5B941',
          error: '#F11A34',
          success: '#8FF2B8',
          'grey-darken1': '#F4F5F9',
          'grey-lighten1': '#EAEBEE',
        }
      }
    }
  },
})